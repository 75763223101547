<template>
  <v-container fluid class="pt-0">
    <loading-bar :value="loading"></loading-bar>
    <v-row dense>
      <template v-if="allHaveFinish || user.vbl">
        <v-col cols="12">
          <v-alert type="warning" class="black--text" :value="division.complete" transition="scale-transition">
            {{alertMsg}}
            <lock-toggle
              :warn="true"
              @unlock-click="onUnlockClick"
              :title="`Unlock ${division.name}?`"
              :inline="true"
              :closeMe="!division.complete"
            >
              <div class="subheading">Results have are been submitted.</div>
              If you change any finishes now you must re-finish the division to update and submit the results.
            </lock-toggle>
          </v-alert>
        </v-col>
        <v-expand-transition>
          <v-col class="text-center" cols="12" v-if="!division.complete && !dirty">
            <v-btn
              color="color3 color3Text--text"
              @click.stop="toggleLock(true)"
              :loading="loading"
            >Lock {{division.name}} results</v-btn>
          </v-col>
        </v-expand-transition>
      </template>
    </v-row>
    <v-row dense>
      <!-- TEAMS CARD -->
      <v-col>
        <v-card height="100%" class="mr-4">
          <v-toolbar color="color2 color2Text--text" dark>
            <v-toolbar-title>
              Teams
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-text-field
              append-icon="fas fa-search"
              label="Search"
              single-line
              hide-details
              v-model="search"
              class="ml-4"
              clearable
              solo-inverted
            ></v-text-field>
          </v-toolbar>
          <v-data-table
            :items="teams"
            :headers="teamHeaders"
            :search="search"
            :items-per-page="-1"
            dense
          >
            <template v-slot:[`item.finish`]="{item}">
                <v-text-field
                  v-model="item.finish"
                  single-line
                  label="Finish"
                  hide-details
                  style="width:50px"
                  class="ma-0 pa-0"
                  color="color3"
                  width="50px"
                  type="number"
                  :disabled="division.complete"
                ></v-text-field>
            </template>
            <template v-slot:[`item.iFinish`]="{item}">
                <v-text-field
                  v-model="item.iFinish"
                  single-line
                  label="Finish"
                  hide-details
                  style="width:50px"
                  class="ma-0 pa-0"
                  color="color3"
                  width="50px"
                  type="number"
                  :disabled="division.complete"
                ></v-text-field>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <!-- RESULTS CARD -->
      <v-col>
        <v-card height="100%" class="mr-4">
          <v-toolbar color="color2 color2Text--text">
            <v-toolbar-title>
              Results
            </v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-row dense v-for="(finish, i) in finishes" :key="i">
              <v-col>
                <place-card :finish="finish" :mode="'validate'"></place-card>
              </v-col>
            </v-row>

            <template v-if="dirty">
              <v-fab-transition>
                <v-btn
                  color="color3 color3Text--text"
                  fab
                  fixed bottom right
                  style="margin-right: 120px"
                  @click.stop="save"
                  :loading="loading"
                >
                  <v-icon>fas fa-save</v-icon>
                </v-btn>
              </v-fab-transition>
              <v-fab-transition>
                <v-btn
                  color="red white--text"
                  fab
                  fixed bottom right
                  style="margin-right: 60px"
                  @click.stop="cancel"
                  :loading="loading"
                >
                  <v-icon>fas fa-times</v-icon>
                </v-btn>
              </v-fab-transition>
            </template>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as PlayoffFormats from '@/classes/BracketTypes'
import LockToggle from '@/components/Utils/LockToggle'
import PlaceCard from '@/components/Tournament/Results/Display/PlaceCard.vue'
import FinishValidator from '@/classes/FinishValidator'
import { mapGetters } from 'vuex'

export default {
  props: ['division', 'active'],
  data () {
    return {
      finishValidator: new FinishValidator(this.division),
      loading: false,
      search: null,
      headers: [
        {
          sortable: false
        },
        {
          text: 'Division',
          align: 'left',
          sortable: true,
          value: 'divisionsString'
        },
        {
          text: 'Teams',
          align: 'center',
          sortable: true,
          value: 'teams.length'
        }
      ],
      teamHeaders: [
        {
          sortable: true,
          text: 'Team Name',
          value: 'name'
        },
        {
          sortable: true,
          text: 'Finish',
          value: 'finish'
        },
        {
          sortable: true,
          text: 'I Finish',
          value: 'iFinish'
        }
      ],
      teamOptions: { itemsperPage: -1 }
    }
  },
  computed: {
    ...mapGetters(['user']),
    finishes () {
      const finishes = this.mode === 'validate' ? this.finishValidator.finishes : this.finishValidator.finishes.filter((f) => {
        return f.teams.length > 0
      })
      const non = this.mode === 'validate' ? this.finishValidator.nonFinishes : this.finishValidator.nonFinishes.filter((f) => {
        return f.teams.length > 0
      })
      return finishes.concat(non).sort((a, b) => {
        return a.value - b.value
      })
    },
    dirty () {
      return this.finishValidator.dirty
    },
    alertMsg () {
      return this.division.complete ? `${this.division.name} is locked. Unlock it to change the results` : `${this.division.name} is un-locked. You must lock it to submit the results`
    },
    allHaveFinish () {
      return !this.teams.find(f => !f.finish)
    },
    teams () {
      return this.division.teams.filter(t => !t.isDeleted)
    },
    showConsolation () {
      return +this.division.playoffTeams !== this.division.teams.length
    },
    playoffFormats () {
      return [
        { text: 'Single elimination', value: PlayoffFormats.SINGLE_ELIM.type },
        { text: 'Single elimination with 3rd/4th playoff', value: PlayoffFormats.SINGLE_ELIM_WITH_4TH.type },
        { text: 'Double elimination with crossover', value: PlayoffFormats.DOUBLE_ELIM_CROSSOVER.type },
        { text: 'Straight double elimination', value: PlayoffFormats.DOUBLE_ELIM.type }
      ]
    }
  },
  methods: {
    update () {
      this.finishValidator.update()
      this.division.validFinishes = this.finishValidator.valid
    },
    cancel () {
      this.finishValidator.reset()
      this.update()
    },
    save () {
      this.saving = true
      this.$VBL.team.saveResults(this.division.resultsDto)
        .then(response => {
          this.finishValidator = new FinishValidator(this.division)
          this.$root.$emit('results-saved', this.division.name)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.saving = false
        })
    },
    onUnlockClick () {
      this.toggleLock(false)
    },
    toggleLock (locked) {
      this.loading = true
      const dto = { complete: locked }
      this.division.patch(dto)
        .catch(err => {
          console.log(err.response)
        })
        .finally(() => {
          this.loading = false
        })
    }
  },
  components: {
    LockToggle,
    PlaceCard
  },
  watch: {
    division: function (val) {
      if (!val) return
      this.finishValidator = new FinishValidator(this.division)
    },
    'division.playoffFormat': function (val) {
      if (!val) return
      this.finishValidator.update()
    }
  }
}
</script>

<style>

</style>
